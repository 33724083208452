<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left" cols="12" md="9">
        <breadcrumb />
      </b-col>

      <!-- Content Right -->
      <b-col class="content-header-right text-md-right" md="3" cols="12">
        <b-button
          @click="showFormModal(null)"
          class="px-3 text-capitalize"
          variant="primary"
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" />
          ایجاد ادمین
        </b-button>
      </b-col>
    </b-row>
    <div v-if="admins">
      <b-row>
        <b-col sm="6" md="3" lg="2">
        <per-page v-model="perPage" class="w-100" />
        </b-col>
        <b-col sm="12" md="7" lg="8">
        <search
        @searchBase="searchBase"
          :selectedFilter="selectedFilter"
          :submitLoading="searchBasesubmitLoading"
          :isSearchResult="isSearchResult"
        
        
        
         ref="adminsSearchComponent" class="w-100" v-model="searchInput" />
        </b-col>
      </b-row>

      <b-card body-class="p-0" class="mt-1">
        <table-head
          ref="adminsTableHeadComponent"
          :items="tableHeadings"
          @sort="sort"
        />
        <b-row
          class="py-1 mx-1 border-bottom"
          v-for="admin in admins"
          :key="admin.id"
        >
          <b-col class="d-flex align-items-center pl-0">
            {{ admin.first_name }}
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ admin.last_name }}
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ admin.mobile }}
          </b-col>
          <b-col class="d-flex align-items-center">
            {{ admin.email }}
          </b-col>
          <b-col
            class="d-flex align-items-center flex-row-reverse"
            style="direction: ltr"
          >
            {{ new Date(admin.created_at).toLocaleString('fa-IR', { timeZone: 'Iran' })}}
          </b-col>
          <b-col class="d-flex align-items-center">
            <b-button
              @click="showConfirmModal(admin)"
              variant="flat-danger"
              class="font-weight-bolder"
            >
              <feather-icon icon="Trash2Icon" size="20" />
            </b-button>
            <b-button
              @click="showFormModal(admin)"
              variant="flat-secondary"
              class="font-weight-bolder"
            >
              <feather-icon icon="Edit2Icon" size="20" />
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <pagination
        ref="adminsPaginationComponent"
        v-model="page"
        :paginationData="paginationData"
      />
    </div>
    <b-sidebar
      width="500px"
      v-model="formModal"
      right
      backdrop
      shadow
      bg-variant="white"
    >
      <template #header>
        <div class="w-100">
          <div class="text-right">
            <b-button
              style="background-color: rgba(75, 75, 75, 0.12)"
              @click="formModal = false"
              variant="flat-dark"
              class="btn-icon rounded-circle mr-1"
            >
              <feather-icon icon="XIcon" size="20" />
            </b-button>
          </div>
          <div class="mr-1 sidebarHeaderTitleDiv">
            <h3 class="text-capitalize">
              {{ editMode ? "ویرایش ادمین" : "ایجاد ادمین" }}
            </h3>
          </div>
        </div>
      </template>
      <b-container class=" mb-3">
        <b-form-group class="mt-3">
          <label for="admin_user" class="text-capitalize"> کاربر :</label>
          <b-form-input v-if="editMode" disabled v-model="adminInput.full_name"></b-form-input>
          <v-select
          v-if="!editMode"
            id="owner_user"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="adminInput.user_id"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :get-option-label="(option) => option.full_name"
            :reduce="(user) => user.id"
            :options="users"
          
            placeholder="برای جستجو تایپ کنید"
          >
            <template #option="{ full_name, mobile }">
              {{ full_name }}
              <br />
              <cite>{{ mobile }}</cite>
            </template>
          </v-select>
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="permissions" class="text-capitalize"> سطوح دسترسی : </label>
            <v-select
            id="admin_user"
            :components="{ OpenIndicator }"
            class="bg-white"
            :clearable="false"
            v-model="adminInput.permissions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :create-option="permission => ({ name: ''})"
            :reduce="permission => ({name: permission.name})"
            :options="permissions"
            label="name"
            taggable multiple
            placeholder="سطوح دسترسی را انتخاب کنید"
          >
          
          </v-select>
        </b-form-group>
      <b-button block class="float-center" v-if="editMode" @click="submitForm('changePermission')" variant="primary">ثبت دسترسی ها</b-button>
      </b-container >
      <hr v-if="editMode" />
           <b-container v-if="editMode" class="">
         <b-form-group class="mt-1">
          <label for="national_code_image" class="text-capitalize">
            عکس پروفایل :
          </label>
          <br />

          <div class="float-left w-50 text-center">
            <b-button
              block
              class="mt-1"
              @click="showCropperModal('avatar', true, null)"
              >انتخاب عکس</b-button
            >

            <b-button
              :disabled="!avatar"
              block
              @click="
                () => {
                  (avatar = null), (avatarCroppedImage = null);
                }
              "
              >حذف عکس</b-button
            >
          </div>
          <div class="float-right w-50 text-center">
            <b-avatar :src="avatarCroppedImage" size="6rem"></b-avatar>
            <!-- <img  width="90%" :src="avatarCroppedImage"/> -->

            <p v-if="avatar">{{ avatar.name }}</p>
          </div>
        </b-form-group>
        <b-form-group  class="mt-1">
          <label for="user_mobile" class="text-capitalize"
            >{{ $t("mobile") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
          dir="ltr"
            id="user_mobile"
            v-model="adminInput.mobile"
            type="text"
            placeholder="+989156662233"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="user_first_name" class="text-capitalize"
            >{{ $t("first name") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
            id="user_first_name"
            v-model="adminInput.first_name"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="user_last_name" class="text-capitalize"
            >{{ $t("last name") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
            id="user_last_name"
            v-model="adminInput.last_name"
            type="text"
          />
        </b-form-group>
 <b-form-group class="mt-1" v-slot="{ ariaDescribedby }">
          <label for="card_number" class="text-capitalize"> {{ $t("gender") }} : </label>
          <b-form-radio
            v-model="adminInput.gender"
            :aria-describedby="ariaDescribedby"
            value="male"
            >مرد</b-form-radio
          >
          <b-form-radio
            v-model="adminInput.gender"
            :aria-describedby="ariaDescribedby"
            value="female"
            >زن</b-form-radio
          >
        </b-form-group>
         <b-form-group class="mt-1">
          <label for="email" class="text-capitalize"
            >{{ $t("email") }}:</label
          >
          <b-form-input
            id="email"
            v-model="adminInput.email"
            type="text"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="user_pass" class="text-capitalize"
            >{{ $t("password") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
            id="user_pass"
            v-model="adminInput.password"
            type="password"
          />
        </b-form-group>
        <b-form-group class="mt-1">
          <label for="user_confirm_pass" class="text-capitalize"
            >{{ $t("password_confirmation") }}:<span class="text-danger">*</span></label
          >
          <b-form-input
            id="confirm_pass"
            v-model="adminInput.password_confirmation"
            type="password"
          />
        </b-form-group>
      </b-container>
      <template #footer>
        <div class="d-flex align-items-center justify-content-end px-2 py-1">
          <b-button
            class="text-capitalize mr-2"
            @click="formModal = false"
            variant="outline-dark"
          >
            {{ $t("cancel") }}
          </b-button>
          <b-button variant="primary" @click="submitForm()">
            <div v-if="submitLoading" class="px-1">
              <b-spinner small style="width: 1.3rem; height: 1.3rem" />
              <span class="sr-only">Loading...</span>
            </div>
            <span v-else class="text-capitalize">
              {{ $t("submit") }}
            </span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
     <image-cropper
      :nationalCodeImg="nationalCodeImg"
      :imageType="modalType"
      :showCropperModal="showModal"
      @closeCropperModal="closeCropperModal"
      @updateImage="updateImage"
    ></image-cropper>
  </div>
</template>

<script>
import {
 BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormRadio
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import ImageCropper from "@/components/ImageCropper.vue";


export default {
  components: {
   BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInput,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    ImageCropper,
    BFormRadio
  },
   data() {
    return {
       submitLoading: false,
      selectedFilter: [],
       isSearchResult:false,
      searchBasesubmitLoading:false,
      avatarCroppedImage: null,
      avatar: null,
      nationalCardCroppedImage: null,
      national_code_image: null,
      nationalCodeImg: null,
      showModal: false,
      modalType: "",
      perPage: 10,
      page: 1,
      users: [],
      permissions:[],
      admins: null,
      defaultAvatar: require("@/assets/images/avatars/default.png"),
      cancelToken: undefined,
      sortBy: "",
      sortDir: "",
      searchInput: "",
      paginationData: null,
      tableHeadings: [
        {
          title: "نام",
          slug: "first_name",
          sortable: true,
        },
        {
          title: "نام خانوادگی",
          slug: "last_name",
          sortable: true,
        },
        {
          title: "موبایل",
          slug: "mobile",
          sortable: true,
        },
        {
          title: "ایمیل",
          slug: "email",
          sortable: true,
        },
        {
          title: "تاریخ ایجاد",
          slug: "created_at",
          sortable: true,
        },
        {
          title: "actions",
          sortable: false,
        },
      ],

      OpenIndicator: {
        render: (createElement) =>
          createElement("feather-icon", { attrs: { icon: "ChevronDownIcon" } }),
      },
      companies: [],
      usersCancelToken: undefined,
      adminInput: {
        id: "",
        user_id: "",
        password: "",
        password_confirmation: "",
        permissions:[],
        full_name:"",
          first_name: "",
        last_name: "",
        mobile: "",
        email:"",
        gender:""
      },
      editMode: false,
      formModal: false,
      submitLoading: false,
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
      this.getUrlParams();

    this.getAdmins();
    this.getUsers();
    this.getPermissions()
  },
  watch: {
    searchInput(value) {
      this.page = 1;
      this.getAdmins();
    },
    perPage(value) {
      this.page = 1;
      this.getAdmins();
    },
    page(value) {
      this.getAdmins();
    },
  },
  methods: {
     getUrlParams() {
      if ("filter[name]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[name]`];
        this.selectedFilter.push("name");
      }
      if ("filter[email]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[email]`];
        this.selectedFilter.push("email");
      }
       if ("filter[mobile]" in this.$route.query) {
        this.searchInput = this.$route.query[`filter[mobile]`];
        this.selectedFilter.push("mobile");
      }
      if ("per_page" in this.$route.query) {
        this.perPage =Number(this.$route.query[`per_page`]);
        console.log("this.userPerPageComponent", this.$refs.userPerPageComponent);
        this.$refs.userPerPageComponent.perPage=this.perPage
      }
       if ("page" in this.$route.query) {
        this.page = this.$route.query[`page`];
      }
      console.log("this.selectedFilter", this.selectedFilter);
    },
    searchBase(data) {
      console.log("data", data);
      this.selectedFilter=[]
      if (data.selectedFilter.length > 0) {
        data.selectedFilter.forEach((item) => {
          if (item == "name") {
            this.searchInput = data.searchInput;
          } else if (item == "mobile") {
            this.searchInput = data.searchInput;
          } else if (item == "email") {
            this.searchInput = data.searchInput;
          }
        });
        this.selectedFilter=data.selectedFilter
      }
      this.searchBasesubmitLoading=true
      if(this.searchInput){
            this.isSearchResult=true
          }
          if(data.type=='delete'){
            this.isSearchResult=false
          }
      this.getAdmins();
    },
      showCropperModal(modalType, showModal, nationalCodeImg) {
      this.modalType = modalType;
      this.showModal = showModal;
      this.nationalCodeImg = { path: nationalCodeImg };
    },
    closeCropperModal() {
      this.modalType = "";
      this.showModal = false;
    },
     updateImage(data) {
      if (data.imageType == "avatar") {
        this.avatarCroppedImage = data.avatarCroppedImage;
        this.avatar = data.avatar;
        console.log("avatar", this.avatar);
      } else if (data.imageType == "national_code_image") {
        this.nationalCardCroppedImage = data.nationalCardCroppedImage;
        this.national_code_image = data.national_code_image;
        console.log("national_code_image", this.national_code_image);
      }
    },
    filteredPermision(permissions){
         var filtered = []
         if(permissions.length>0){
      permissions.forEach(permission => {
        filtered.push({name:permission.name})
      })}
      return filtered
    },
      getPermissions(){
    if (typeof this.usersCancelToken != typeof undefined) {
        this.usersCancelToken.cancel(
          "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.usersCancelToken = this.$http.CancelToken.source();

      let data = {
       
      };

      const requestData = {
        method: "get",
        url: "/admin/permissions",
        params: data,
        cancelToken: this.usersCancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.permissions = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
   },
    sort(args) {
      this.page = 1;
      this.sortBy = args.sortBy;
      this.sortDir = args.sortDir;
      this.getAdmins();
    },

    async getAdmins() {
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      let data = {
        search: this.searchInput,
        per_page: this.perPage,
        page: this.page,
        sort_by: this.sortBy,
        sort_dir: this.sortDir,
      };

      const requestData = {
          method: "get",
        url: "/admin/users/admin",
        params: data,
        // cancelToken: this.cancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.admins = data.data;
          this.paginationData = data.meta;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showConfirmModal(admin) {
      this.$bvModal
        .msgBoxConfirm(
          `آیا از حذف ادمین مطمئن هستید ؟  : ${admin.first_name} ${admin.last_name}.`,
          {
            title: this.$t("Please Confirm"),
            size: "sm",
            okVariant: "primary",
            okTitle: this.$t("Yes"),
            cancelTitle: this.$t("No"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async (confirmed) => {
          if (confirmed) {
            let adminIndex = 0;
            this.admins = await this.admins.filter((adminItem, index) => {
              if (adminItem.id == admin.id) {
                adminIndex = index;
              }
              return adminItem.id !== admin.id;
            });

            const requestData = {
              method: "delete",
              url: `/admin/users/delete/${admin.id}`,
            };
            this.$http(requestData)
              .then((response) => {
                this.$bvToast.toast(
                  `ادمین: ${admin.first_name} ${admin.last_name} با موفقیت حذف شد.`,
                  {
                    title: this.$t("Done"),
                    variant: "success",
                    solid: true,
                  }
                );
              })
              .catch((err) => {
                let message = this.$t("Unable to delete!");
                if (
                  err.response &&
                  err.response.data &&
                  err.response.data.message
                )
                  message = err.response.data.message;
                this.$bvToast.toast(message, {
                  title: this.$t("Error"),
                  variant: "danger",
                  solid: true,
                });
                this.admins.splice(adminIndex, 0, admin);
              });
          }
        });
    },
    showFormModal(admin) {
      if (admin) {
        this.adminInput = {
          id: admin.id,
          user_id: admin.id,
          password: "",
          password_confirmation: "",
          permissions:this.filteredPermision(admin.permissions),
          full_name:admin.full_name,
          first_name:admin.first_name,
          last_name:admin.last_name,
          gender:admin.gender,
          email:admin.email,
          mobile:admin.mobile
         
        };
        this.editMode = true;
      } else {
        this.adminInput = {
          id: "",
          user_id: "",
          password: "",
          password_confirmation: "",
        };
        this.editMode = false;
      }
      this.formModal = true;
    },
     updateuserinfo(){
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.adminInput.mobile) {
        errorMessage = "لطفا موبایل کاربر را وارد کنید";
      }
      if (!errorMessage && !this.adminInput.first_name) {
        errorMessage = "لطفا نام کاربر را وارد کنید";
      }
      if (!errorMessage && this.adminInput.first_name.length < 2) {
        errorMessage = "نام کاربر نمی تواند کمتر از دو کاراکتر باشد";
      }
      if (!errorMessage && !this.adminInput.last_name) {
        errorMessage = "لطفا نام خانوادگی کاربر را وارد کنید";
      }

      if (!errorMessage && this.adminInput.last_name.length < 2) {
        errorMessage = "نام  خانوادگی کاربر نمی تواند کمتر از دو کاراکتر باشد";
      }
      if (!errorMessage && !this.adminInput.mobile) {
        errorMessage = "لطفا شماره موبایل کاربر را وارد کنید";
      }
      if (!errorMessage && !this.validateMobile()) {
        errorMessage = "لطفا شماره موبایل صحیح وارد کنید";
      }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;
      let formData=new FormData()
      if(this.avatar){
      formData.append("avatar", this.avatar);}
      if(this.adminInput.first_name){
      formData.append("first_name", this.adminInput.first_name);}
      if(this.adminInput.last_name){
      formData.append("last_name", this.adminInput.last_name);}
      if(this.adminInput.mobile){
      formData.append("mobile", this.adminInput.mobile);}
      if(this.adminInput.email){
      formData.append("email", this.adminInput.email);}
      if(this.adminInput.gender){
      formData.append("gender", this.adminInput.gender);}
      if(this.adminInput.password){
      formData.append("password", this.adminInput.password);}
      if(this.adminInput.password_confirmation){
      formData.append("password_confirmation", this.adminInput.password_confirmation);}
      const requestData = {
        method: "post",
        url:  `/admin/users/update/${this.adminInput.id}`,
        data: formData,
      };

      this.$http(requestData)
        .then((response) => {
          let message = "کاربر با موفقیت اضافه شد";
          if (this.editMode) message = "کاربر با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          if (!this.editMode && this.users && this.users.length > 0) {
            this.$refs.usersPaginationComponent.clear();
            this.$refs.usersSearchComponent.clear();
            this.$refs.usersTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          this.getAdmins();
        })
        .catch((err) => {
          let message = "خطا در ایجاد کاربر";
          if (this.editMode) message = "خطا در ویرایش کاربر";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
        });
console.log("updatedata")
    },
    submitForm(type) {
      if(this.editMode && type!=='changePermission'){
      this.updateuserinfo()
      return
      }
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && !this.adminInput.user_id) {
        errorMessage = "لطفا یک کاربر را انتخاب کنید";
      }
      // if (!errorMessage && !this.adminInput.password) {
      //   errorMessage = "لطفا رمز را وارد کنید";
      // }
      // if (!errorMessage && this.adminInput.password.length < 8) {
      //   errorMessage = "رمز نمی تواند کمتر از 8 کاراکتر باشد";
      // }
      // if (!errorMessage && !this.adminInput.password_confirmation) {
      //   errorMessage = "لطفا تکرار رمز را وارد کنید";
      // }
      // if (
      //   !errorMessage &&
      //   this.adminInput.password != this.adminInput.password_confirmation
      // ) {
      //   errorMessage = "رمز و تکرار رمز یکسان نیستند";
      // }

      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

      const requestData = {
       method: "post",
        url:  `/admin/users/create/admin`,
        data: this.adminInput,
      };

      this.$http(requestData)
        .then((response) => {
           let message = "ادمین با موفقیت اضافه شد";
          if (this.editMode) message = "ادمین با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          if (!this.editMode && this.users && this.users.length > 0) {
            this.$refs.adminsPaginationComponent.clear();
            this.$refs.adminsSearchComponent.clear();
            this.$refs.adminsTableHeadComponent.clear();
            this.page = 1;
            this.searchInput = "";
            this.sortBy = "";
            this.sortDir = "";
          }

          this.submitLoading = false;
          this.formModal = false;
          this.getAdmins();
        })
        .catch((err) => {
                    let message = "خطا در ایجاد ادمین";
          if (this.editMode) message = "خطا در ویرایش ادمین";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;

          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
        });
    },
    getUsers(search, loading) {
      if (typeof this.usersCancelToken != typeof undefined) {
        this.usersCancelToken.cancel(
          "Operation canceled due to new request."
        );
      }
      //Save the cancel token for the current request
      this.usersCancelToken = this.$http.CancelToken.source();

      let data = {
        search: search,
        per_page: 10,
        page: 1,
      };

      const requestData = {
        method: "get",
        url: "/admin/users/get_all_users",
        params: data,
        // cancelToken: this.usersCancelToken.token,
      };
      this.$http(requestData)
        .then((response) => {
          let data = response.data;
          this.users = data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
     validateMobile() {
      return String(this.adminInput.mobile)
        .toLowerCase()
        .match(
          /\+989([0-9]{9})/
          
        );
    },
   
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
